import React, {Component} from 'react';

class Image extends Component{

    state = {
        image: null,
        loading: true
    }

    async fetchImageFromURL(url) {
        try {
          const response = await fetch(url);
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
      
          return imageUrl;
        } catch (error) {
          console.error('Error fetching image:', error);
          return null;
        }
      }

    async componentDidMount(){
      const image = await this.fetchImageFromURL(this.props.src);
      this.setState({image, loading: false});
    }
    render(){
       return(
        <>
        {this.state.loading && (
          <img
            loading='lazy'
            src={window.LOADING_IMAGE}
            alt='...'
            style={{
              height: '2rem'
            }}
          />
        )}
        {this.state.image && (
          <img
            loading='lazy'
            id={this.props.id}
            className={this.props.className}
            src={this.state.image}
            alt='...'
          />
        )}
        </>
      )
    }
}


export default Image;