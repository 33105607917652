class Storefront {
    constructor() {}

    domain = window.SHOPIFY_CONFIG.domain;
    token = window.SHOPIFY_CONFIG.access_token;

    /* cart query */

    async crateCart(){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        mutation createCart($cartInput: CartInput) {
            cartCreate(input: $cartInput) {
                cart {
                    id
                    createdAt
                    updatedAt
                    checkoutUrl
                    totalQuantity
                    lines(first: 100) {
                        edges {
                            node {
                                id
                                quantity
                                merchandise {
                                    ... on ProductVariant {
                                        id
                                    }
                                }
                                attributes {
                                    key
                                    value
                                }
                            }
                        }
                    }
                    attributes {
                        key
                        value
                    }
                    cost {
                        totalAmount {
                            amount
                            currencyCode
                        }
                        subtotalAmount {
                            amount
                            currencyCode
                        }
                        totalTaxAmount {
                            amount
                            currencyCode
                        }
                        totalDutyAmount {
                            amount
                            currencyCode
                        }
                    }
                    buyerIdentity {
                        email
                        phone
                        customer {
                            id
                        }
                        countryCode
                    }
                }
                userErrors {
                    field
                    message
                }
            }
        }
          `;
        const input = {
            "cartInput": {
                "lines": [],
                "attributes": {
                    "key": "type",
                    "value": "unauthenticated"
                }
            }
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.cartCreate != null){
                // console.log(content.data); //TEST
                return content.data.cartCreate.cart;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async addProductsToCart(cartId, productVariantId, quantity, attr_json){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        mutation addCartLines($cartId: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
                cart {
                    id
                    createdAt
                    updatedAt
                    checkoutUrl
                    totalQuantity
                    lines(first: 100) {
                        edges {
                            node {
                                id
                                quantity
                                merchandise {
                                    ... on ProductVariant {
                                        id
                                    }
                                }
                                attributes {
                                    key
                                    value
                                }
                            }
                        }
                    }
                    attributes {
                        key
                        value
                    }
                    cost {
                        totalAmount {
                            amount
                            currencyCode
                        }
                        subtotalAmount {
                            amount
                            currencyCode
                        }
                        totalTaxAmount {
                            amount
                            currencyCode
                        }
                        totalDutyAmount {
                            amount
                            currencyCode
                        }
                    }
                    buyerIdentity {
                        email
                        phone
                        customer {
                            id
                        }
                        countryCode
                    }
                }   
          
                userErrors {
                    field
                    message
                }
            }
        }
          
          `;
        const input = {
        
            "cartId": cartId,
            "lines": {
                "merchandiseId": productVariantId,
                "quantity": quantity,
                "attributes": [
                    {
                      "key": "item",
                      "value": JSON.stringify(attr_json)
                    }
                ],
            }
        
        };

        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.cartLinesAdd != null){
                // console.log(content.data); //TEST
                return content.data.cartLinesAdd.cart;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async updateProductInCart(cartId, lineId, quantity, attr_json){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        mutation updateCartLines($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
            cartLinesUpdate(cartId: $cartId, lines: $lines) {
                cart {
                    id
                    createdAt
                    updatedAt
                    checkoutUrl
                    totalQuantity
                    lines(first: 100) {
                        edges {
                            node {
                                id
                                quantity
                                merchandise {
                                    ... on ProductVariant {
                                        id
                                    }
                                }
                                attributes {
                                    key
                                    value
                                }
                            }
                        }
                    }
                    attributes {
                        key
                        value
                    }
                    cost {
                        totalAmount {
                            amount
                            currencyCode
                            }
                        subtotalAmount {
                            amount
                            currencyCode
                        }
                        totalTaxAmount {
                            amount
                            currencyCode
                        }
                        totalDutyAmount {
                            amount
                            currencyCode
                        }
                    }
                    buyerIdentity {
                        email
                        phone
                        customer {
                            id
                        }
                        countryCode
                    }
                }
                userErrors {
                    field
                    message
                }
            }
        }     
          `;
        let input;
        if(attr_json != null)
            input =
            {
                "cartId": cartId,
                "lines": {
                    "id": lineId,
                    "quantity": quantity,
                    "attributes": [
                        {
                        "key": "item",
                        "value": JSON.stringify(attr_json)
                        }
                    ],
                }
            };
        else
            input =
            {
                "cartId": cartId,
                "lines": {
                    "id": lineId,
                    "quantity": quantity,
                }
            };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.cartLinesUpdate != null){
                // console.log(content.data); //TEST
                return content.data.cartLinesUpdate.cart;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async removeProductFromCart(cartId, lineId){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
            cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                cart {
                    id
                    createdAt
                    updatedAt
                    checkoutUrl
                    totalQuantity
                    lines(first: 100) {
                      edges {
                        node {
                          id
                          quantity
                          merchandise {
                            ... on ProductVariant {
                              id
                            }
                          }
                          attributes {
                            key
                            value
                          }
                        }
                      }
                    }
                    attributes {
                      key
                      value
                    }
                    cost {
                      totalAmount {
                        amount
                        currencyCode
                      }
                      subtotalAmount {
                        amount
                        currencyCode
                      }
                      totalTaxAmount {
                        amount
                        currencyCode
                      }
                      totalDutyAmount {
                        amount
                        currencyCode
                      }
                    }
                    buyerIdentity {
                      email
                      phone
                      customer {
                        id
                      }
                      countryCode
                    }
                }
                
                userErrors {
                    field
                    message
                }
            }
        }   
          `;
        const input =
        {
            "cartId": cartId,
            "lineIds": [
                lineId
            ]
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.cartLinesRemove != null){
                //console.log(content.data); //TEST
                return content.data.cartLinesRemove.cart;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getCart(cartId){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query cartQuery($cartId: ID!) {
            cart(id: $cartId) {
                id
                createdAt
                updatedAt
                checkoutUrl
                totalQuantity
                lines(first: 100) {
                    edges {
                        node {
                            id
                            quantity
                            merchandise {
                                ... on ProductVariant {
                                    id
                                }
                            }
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }
                attributes {
                    key
                    value
                }
                cost {
                    totalAmount {
                        amount
                        currencyCode
                    }
                    subtotalAmount {
                        amount
                        currencyCode
                    }
                    totalTaxAmount {
                        amount
                        currencyCode
                    }
                    totalDutyAmount {
                        amount
                        currencyCode
                    }
                }
                buyerIdentity {
                    email
                    phone
                    customer {
                        id
                    }
                    countryCode
                }
            }
          }
            
          `;
        const input =
        {
            "cartId": cartId
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content){
                //console.log(content.data); //TEST
                return content.data.cart;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getCheckoutUrl(cartId){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query checkoutURL($cartId: ID!) {
            cart(id: $cartId) {
              checkoutUrl
              lines(first: 100) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
          `;
        const input =
        {
            "cartId": cartId
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content &&  content.data.cart != null){
                // console.log(content.data); //TEST
                if(content.data.cart.lines.edges.length > 0)
                    return content.data.cart.checkoutUrl;
                else
                    return null;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    /* -------- */

    async getCollection(collectionId, limit, sortkey = "TITLE", cursor = null){
        if(cursor != null)
            cursor = `"${cursor}"`;

        switch(sortkey){
            case "BEST_SELLING": break;
            case "PRICE": break;
            case "PRODUCT_TYPE": break;
            case "TITLE": break;
            case "VENDOR": break;
            case "UPDATED_AT": break;
            default: 
                sortkey = "TITLE";
                break;
        }
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `{
            collection(id: "${collectionId}"){
                products(first: ${limit}, after: ${cursor}, sortKey: ${sortkey}){
                    edges{
                        cursor
                        node{
                            id
                            title
                            totalInventory
                            vendor
                            tags
                            productType
                            compareAtPriceRange{
                                maxVariantPrice{
                                    amount
                                    currencyCode
                                }
                                minVariantPrice{
                                    amount
                                    currencyCode
                                }
                            }
                            priceRange{
                                maxVariantPrice{
                                    amount
                                    currencyCode
                                }
                                minVariantPrice{
                                    amount
                                    currencyCode
                                }
                            }
                            banner: metafield(namespace: "custom", key: "banner"){
                                value
                            }
                            colors: metafield(namespace: "custom", key: "colori"){
                                value
                            }
                            featuredImage{
                                url
                            }
                        }
                    }
                }
            }
        }`;
        const body = JSON.stringify({
            query: query
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token':this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content){
                // console.log(content.data.collection.products.edges); //TEST
                if(content.data.collection != null)
                    return content.data.collection.products.edges;
                return [];
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getProduct(productId){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `{
            product(id: "${productId}") {
                id
                title
                description
                totalInventory
                vendor
                tags
                productType
                compareAtPriceRange{
                    maxVariantPrice{
                        amount
                        currencyCode
                    }
                    minVariantPrice{
                        amount
                        currencyCode
                    }
                }
                priceRange{
                    maxVariantPrice{
                        amount
                        currencyCode
                    }
                    minVariantPrice{
                        amount
                        currencyCode
                    }
                }
                variants(first: 1){
                    nodes{
                        id
                    }
                }
                banner: metafield(namespace: "custom", key: "banner"){
                    value
                }
                caratteristiche: metafield(namespace: "custom", key: "caratteristiche"){
                    value
                }
                gastronomia: metafield(namespace: "custom", key: "gastronomia"){
                    value
                }
                vinificazione: metafield(namespace: "custom", key: "vinificazione"){
                    value
                }
                gradazione: metafield(namespace: "custom", key: "gradazione"){
                    value
                }
                impianto: metafield(namespace: "custom", key: "sesto_impianto"){
                    value
                }
                terreno: metafield(namespace: "custom", key: "tipologia_del_terreno"){
                    value
                }
                uvaggio: metafield(namespace: "custom", key: "uvaggio"){
                    value
                }
                zona: metafield(namespace: "custom", key: "zona_di_produzione"){
                    value
                }
                qualifica: metafield(namespace: "custom", key: "qualifica"){
                    value
                }
                colors: metafield(namespace: "custom", key: "colori"){
                    value
                }
                images(first: 3){
                    nodes{
                        url
                    }
                }
            }
          }`;
        const body = JSON.stringify({
            query: query
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token':this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content){
                // console.log(content.data.product); //TEST
                return content.data.product;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async searchProducts(inputText){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query suggestions($query: String!, $limit: Int, $searchableFields: [SearchableField!]){
            predictiveSearch(query: $query, limit: $limit, types: PRODUCT, searchableFields: $searchableFields){
                products {
                            id
                            title
                            vendor
                            productType
                            featuredImage{
                                url
                            }
                }
            }
        }
          `;
        const input = {
            "query": `${inputText}`,
            "first": 10,
            "searchableFields": ['TITLE', 'VENDOR', 'PRODUCT_TYPE']
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                //console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.predictiveSearch != null){
                // console.log(content.data.predictiveSearch); //TEST
                return content.data.predictiveSearch;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getAllTags(limit){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query getProductTags($limit: Int!) {
            productTags(first: $limit) {
              edges{
                      node
                  }
            }
          }
          `;
        const input = {
            "limit": limit
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.productTags != null){
                // console.log(content.search); //TEST
                return content.data.productTags;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getAllCollectionNames(limit){
        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query getCollections($limit: Int!) {
            collections(first: $limit) {
                edges {
                    node {
                        id
                        title
                        category: metafield(namespace: "custom", key: "categoria"){
                            value
                        }
                        tag: metafield(namespace: "custom", key: "titolo_menu"){
                            value
                        }
                    }
                }
            }
          }
          `;
        const input = {
            "limit": limit
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.collections != null){
                // console.log(content.search); //TEST
                return content.data.collections;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }

    async getProductsByTags(filter, limit, sortkey = "TITLE", cursor = null){

        if(cursor != null)
            cursor = `"${cursor}"`;

        let reverse = false;
        switch(sortkey){
            case "BEST_SELLING": break;
            case "PRICE": break;
            case "PRICE R": reverse = true; sortkey= "PRICE"; break;
            case "TITLE": break;
            case "TITLE R": reverse = true; sortkey= "TITLE"; break;
            case "VENDOR": break;
            case "VENDOR R": reverse = true; sortkey= "VENDOR"; break;
            case "UPDATED_AT": break;
            default: 
                sortkey = "TITLE";
                break;
        }

        const url = 'https://' + this.domain + '/api/2023-07/graphql.json';
        const query =  `
        query getProducts($query: String, $first: Int, $sortKey: ProductSortKeys, $reverse:Boolean,  $after: String){
            products(query: $query, first: $first, sortKey: $sortKey, reverse: $reverse, after: $after){
                edges{
                    cursor
                    node{
                        id
                        title
                        totalInventory
                        vendor
                        tags
                        productType
                        compareAtPriceRange{
                            maxVariantPrice{
                                amount
                                currencyCode
                            }
                            minVariantPrice{
                                amount
                                currencyCode
                            }
                        }
                        priceRange{
                            maxVariantPrice{
                                amount
                                currencyCode
                            }
                            minVariantPrice{
                                amount
                                currencyCode
                            }
                        }
                        banner: metafield(namespace: "custom", key: "banner"){
                            value
                        }
                        colors: metafield(namespace: "custom", key: "colori"){
                            value
                        }
                        featuredImage{
                            url
                        }
                    }
                }
            }
        }
          `;
        const input = {
            "query": filter,
            "first": limit,
            "sortKey": sortkey,
            "reverse": reverse,
            "after": cursor
        };
        const body = JSON.stringify({
            query: query,
            variables: input
        });

        return fetch(url, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': this.token
            },
            body: body
        }).then(response => {
            if (response.status === 200) {
                const data = response.json();
                // console.log(data);
                return data;
            } else {
                console.error('Request failed to server: ' + response.status);
                return null;
            }
        }).then(content => {
            if (content != null && 'data' in content && content.data.products != null){
                // console.log(content.search); //TEST
                return content.data.products.edges;
            }
            else if(content != null && 'errors' in content){
                console.error(content.errors); //TEST
                return null;
            }
        });
    }
}

export default Storefront;

