import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../style/desktop/Menu.css'
import '../style/mobile/Menu.css'
import MultiRangeSlider from "multi-range-slider-react";

class Menu extends Component{

  state = {
    filters: {...this.props.filters},
    animation_duration: window.MENU_CONFIG.item_animation_duration,
    locations: [],
    typologies: [],
    producers: [],
    slider: {
      minValue: window.MENU_CONFIG.section_2.min_price,
      maxValue: window.MENU_CONFIG.section_2.max_price
    },
    redirect: false
  }

  shouldComponentUpdate(nextProps, nextState) {

    if(nextProps.collections !== this.props.collections){
      
      let locations = [];
      let typologies = [];
      let producers = [];

      let collections = nextProps.collections;
      for(let i = 0; i < collections.length; i++){
          let item = {};
          item.gid = collections[i].node.id;
          if(collections[i].node.tag == null)
            continue;
          item.name = collections[i].node.tag.value;

          let value = collections[i].node.category.value;
          if(value == window.MENU_CONFIG.section_1.sub_sections[0].key){
            item.id = locations.length;
            locations.push(item);
          }
          else if(value == window.MENU_CONFIG.section_1.sub_sections[1].key){
            item.id = typologies.length;
              typologies.push(item);
          }
          else if(value == window.MENU_CONFIG.section_1.sub_sections[2].key){
            item.id = producers.length;
              producers.push(item);
          }
      }

      this.setState({locations, typologies, producers});
    }

    if(nextProps.filters !== this.props.filters)
      this.props.autoSwipe();

    if( 
      nextState.locations !== this.state.locations ||
      nextState.typologies !== this.state.typologies ||
      nextState.producers !== this.state.producers ||
      nextState.filters !== this.state.filters ||
      nextProps.filters !== this.props.filters ||
      nextProps.filter_enabler !== this.props.filter_enabler
    ){
      // console.log('update'); // TEST
      return true;
    }
    return false;
  }

  applyFilters = () => {
    this.props.applyFilters(this.state.filters);
    this.props.closeMenu();
  }

  removeFilters(){ // NOT USED
    let filters = {
      locations: [],
      typologies: [],
      producers: [],
      minPrice: this.state.slider.minValue,
      maxPrice: this.state.slider.maxValue,
      sorting: 'TITLE'
    };
    let collections = document.getElementsByClassName('menu-item-content');
    for(let i = 0; i < collections.length; i++){
      let items = collections[i].getElementsByTagName('div');
      for(let j = 0; j < items.length; j++){
        let object = items[j];
        object.style.backgroundColor = 'var(--color10)';
        object.style.color = 'var(--color6)';
      }
    }
    
    document.getElementById('menu-sorting-list').style.display = 'none';
    document.getElementById('menu-sorting-icon').src = window.MENU_CONFIG.down_arrow_icon;
    document.getElementById('menu-sorting-selected').innerText = window.MENU_CONFIG.section_3.sorting[0].name;
    document.getElementById('menu-price-slider').getElementsByClassName('input-type-range input-type-range-min')[0].value = this.state.slider.minValue;
    document.getElementById('menu-price-slider').getElementsByClassName('input-type-range input-type-range-max')[0].value = this.state.slider.maxValue;
    document.getElementById('menu-price-slider').getElementsByClassName('labels')[0].childNodes[0].innerText = this.state.slider.minValue;
    document.getElementById('menu-price-slider').getElementsByClassName('labels')[0].childNodes[1].innerText = this.state.slider.maxValue;

    this.props.applyFilters(filters);
    this.props.closeMenu();
  }

  onMenuHeader(collection){
    let collectionObject = document.getElementsByName(collection)[0];
    if(collectionObject.endAnimation != null && collectionObject.endAnimation == false)
            return;
    collectionObject.endAnimation = false;
    let animation_duration = this.state.animation_duration;

    if (collectionObject.style.display == 'flex'){

      const tempStyleElement = document.createElement("style");
      tempStyleElement.textContent = `
          @keyframes close-${collection}-header {
              from {
                  height: ${collectionObject.max_h}px;
              }
              to {
                height: 0;
              }
          }
      `;
      document.head.appendChild(tempStyleElement);
      collectionObject.style.animation = `close-${collection}-header ${animation_duration}ms linear forwards`;

      document.getElementsByName(collection + '-arrow')[0].src = window.MENU_CONFIG.down_arrow_icon;
      setTimeout(() => {
        collectionObject.style.display = 'none';
        collectionObject.endAnimation = true;
        document.head.removeChild(tempStyleElement);
      }, animation_duration);
    }
    else{
      collectionObject.style.display = 'flex';
      let max = collectionObject.clientHeight;
      if(collectionObject.max_h == null)
        collectionObject.max_h = max;
      // collectionObject.style.height = '0px';
      
      const tempStyleElement = document.createElement("style");
      tempStyleElement.textContent = `
          @keyframes open-${collection}-header {
              from {
                  height: 0;
              }
              to {
                height: ${collectionObject.max_h}px;
              }
          }
      `;
      document.head.appendChild(tempStyleElement);
      collectionObject.style.animation = `open-${collection}-header ${animation_duration}ms linear forwards`;
      
      document.getElementsByName(collection + '-arrow')[0].src = window.MENU_CONFIG.up_arrow_icon;
      setTimeout(() => {
        collectionObject.endAnimation = true;
        document.head.removeChild(tempStyleElement);
      }, animation_duration);
    }
  }

  onMenuItem(collection, item){
    
    let filters = {...this.state.filters};
    
    if (collection == 'nav-locations' && filters.locations.includes(item.name)){
      filters.locations.splice(filters.locations.indexOf(item.name), 1);
    }
    else if(collection == 'nav-locations' && !filters.locations.includes(item.name)){
      filters.locations.push(item.name);
    }
    if (collection == 'nav-typologies' && filters.typologies.includes(item.name)){
      filters.typologies.splice(filters.typologies.indexOf(item.name), 1);
    }
    else if(collection == 'nav-typologies' && !filters.typologies.includes(item.name)){
      filters.typologies.push(item.name);
    }
    if (collection == 'nav-producers' && filters.producers.includes(item.name)){
      filters.producers.splice(filters.producers.indexOf(item.name), 1);
    }
    else if(collection == 'nav-producers' && !filters.producers.includes(item.name)){
      filters.producers.push(item.name);
    }

    this.setState({filters});
    
  }

  handleSlider(e){
    // console.log('handle slider'); // TEST
    let filters = {...this.state.filters};
    let labels = document.getElementById('menu-price-slider').getElementsByClassName('label');
    labels[0].textContent = e.minValue;
    labels[1].textContent = e.maxValue;
    if(filters.minPrice == e.minValue && filters.maxPrice == e.maxValue)
      return;
    filters.minPrice = e.minValue;
    filters.maxPrice = e.maxValue;
    document.getElementsByClassName('menu-price-input')[0].value = e.minValue.toString();
    document.getElementsByClassName('menu-price-input')[1].value = e.maxValue.toString();
    this.setState({filters});
  }

  onInputMinPriceBlur(object){
    
    if(object.value == '')
      object.value = this.state.slider.minValue; 

    let minValue = document.getElementsByClassName('menu-price-input')[0].value;
    let maxValue = document.getElementsByClassName('menu-price-input')[1].value;
    if(minValue == '')
      return;
    if(parseInt(minValue) < this.state.slider.minValue || parseInt(minValue) > this.state.slider.maxValue || parseInt(minValue) > parseInt(maxValue)){
      minValue = this.state.slider.minValue;
      document.getElementsByClassName('menu-price-input')[0].value = minValue.toString();
    }
    let filters = {...this.state.filters};
    filters.minPrice = minValue;
    document.getElementById('menu-price-slider').getElementsByClassName('input-type-range input-type-range-min')[0].value = minValue;
    document.getElementById('menu-price-slider').getElementsByClassName('labels')[0].childNodes[0].innerText = minValue;
    this.setState({filters});
  }

  onInputMaxPriceBlur(object){
    if(object.value == '')
      object.value = this.state.slider.maxValue;

    let minValue = document.getElementsByClassName('menu-price-input')[0].value;
    let maxValue = document.getElementsByClassName('menu-price-input')[1].value;
    if(maxValue == '')
      return;
    if(parseInt(maxValue) < this.state.slider.minValue || parseInt(maxValue) > this.state.slider.maxValue || parseInt(minValue) > parseInt(maxValue)){
      maxValue = this.state.slider.maxValue;
      document.getElementsByClassName('menu-price-input')[1].value = maxValue.toString();
    }
    let filters = {...this.state.filters};
    filters.maxPrice = maxValue;
    document.getElementById('menu-price-slider').getElementsByClassName('input-type-range input-type-range-max')[0].value = maxValue;
    document.getElementById('menu-price-slider').getElementsByClassName('labels')[0].childNodes[1].innerText = maxValue;
    this.setState({filters});
    
  }

  openSortingList = () => {
    let list = document.getElementById('menu-sorting-list');
    if(list.endAnimation != null && list.endAnimation == false)
            return;
    list.endAnimation = false;
    let animation_duration = this.state.animation_duration;

    if(list.style.display == 'flex'){
      const tempStyleElement = document.createElement("style");
      tempStyleElement.textContent = `
          @keyframes close-sorting-list {
              from {
                  height: ${list.max_h}px;
              }
              to {
                height: 0;
              }
          }
      `;
      document.head.appendChild(tempStyleElement);
      list.style.animation = `close-sorting-list ${animation_duration}ms linear forwards`;

      document.getElementById('menu-sorting-icon').src = window.MENU_CONFIG.down_arrow_icon;
      setTimeout(() => {
        list.style.display = 'none';
        list.endAnimation = true;
        document.head.removeChild(tempStyleElement);
      }, animation_duration);
    }
    else{

      list.style.display = 'flex';
      let max = list.clientHeight;
      if(list.max_h == null)
        list.max_h = max;
      // list.style.height = '0px';
      
      const tempStyleElement = document.createElement("style");
      tempStyleElement.textContent = `
          @keyframes open-sorting-list {
              from {
                  height: 0;
              }
              to {
                height: ${list.max_h}px;
              }
          }
      `;
      document.head.appendChild(tempStyleElement);
      list.style.animation = `open-sorting-list ${animation_duration}ms linear forwards`;
      
      document.getElementById('menu-sorting-icon').src = window.MENU_CONFIG.up_arrow_icon;
      setTimeout(() => {
        list.endAnimation = true;
        document.head.removeChild(tempStyleElement);
      }, animation_duration);
    }
  }

  slectSortingValue = (key, value) => {
    let filters = {...this.state.filters};
    filters.sorting = key;
    document.getElementById('menu-sorting-list').style.display = 'none';
    document.getElementById('menu-sorting-icon').src = window.MENU_CONFIG.down_arrow_icon;
    document.getElementById('menu-sorting-selected').innerText = value;
    this.setState({filters});
  }
  
  render(){

    let title = '';
    let menu_content_filters = <></>;
    let menu_pages = <></>
    if(this.props.filter_enabler){
      title = window.MENU_CONFIG.filters_title;;
      menu_content_filters = <>
        <label className='menu-subtitle'>{window.MENU_CONFIG.section_1.title}</label>

        <div className='menu-item'>
          <div className='menu-item-header' onClick={() => this.onMenuHeader('nav-locations')}>
              <div className='menu-item-header-container'>
              <div className='menu-item-header-img-container'>
                  <img loading='lazy' className='menu-item-header-img' src={window.MENU_CONFIG.section_1.sub_sections[0].icon} alt='...' />
              </div>
              <label className='menu-item-header-name'>{window.MENU_CONFIG.section_1.sub_sections[0].title}</label>
              </div>
              <img loading='lazy' className='menu-item-header-arrow' src={window.MENU_CONFIG.down_arrow_icon} alt='Espandi Sottomenù' name='nav-locations-arrow'/> 
          </div>
          <div className='menu-item-separator'></div>
          <div className='menu-item-content' name='nav-locations'>
              {
              this.state.locations.map(location => (
                  <div 
                    key={location.id} 
                    className={this.state.filters.locations.includes(location.name) ? 'menu-item-content-item-selected' : 'menu-item-content-item'}
                    onClick={()=>this.onMenuItem('nav-locations', location)}>
                      {location.name}
                  </div>
              ))
              }
          </div>
        </div>

        <div className='menu-item'>
          <div className='menu-item-header' onClick={() => this.onMenuHeader('nav-typologies')}>
              <div className='menu-item-header-container'>
              <div className='menu-item-header-img-container'>
                  <img loading='lazy' className='menu-item-header-img' src={window.MENU_CONFIG.section_1.sub_sections[1].icon} alt='...' />
              </div>
              <label className='menu-item-header-name'>{window.MENU_CONFIG.section_1.sub_sections[1].title}</label>
              </div>
              <img loading='lazy' className='menu-item-header-arrow' src={window.MENU_CONFIG.down_arrow_icon} alt='Espandi Sottomenù' name='nav-typologies-arrow'/> 
          </div>
          <div className='menu-item-separator'></div>
          <div className='menu-item-content' name='nav-typologies'>
              {
              this.state.typologies.map(typology => (
                    <div 
                      key={typology.id} 
                      className={this.state.filters.typologies.includes(typology.name) ? 'menu-item-content-item-selected' : 'menu-item-content-item'}
                      onClick={()=>this.onMenuItem('nav-typologies', typology)}>
                        {typology.name}
                    </div>
              ))
              }
          </div>
        </div>

        <div className='menu-item'>
          <div className='menu-item-header' onClick={() => this.onMenuHeader('nav-producers')}>
              <div className='menu-item-header-container'>
              <div className='menu-item-header-img-container'>
                  <img loading='lazy' className='menu-item-header-img' src={window.MENU_CONFIG.section_1.sub_sections[2].icon} alt='...' />
              </div>
              <label className='menu-item-header-name'>{window.MENU_CONFIG.section_1.sub_sections[2].title}</label>
              </div>
              <img loading='lazy' className='menu-item-header-arrow' src={window.MENU_CONFIG.down_arrow_icon} alt='Espandi Sottomenù' name='nav-producers-arrow'/> 
          </div>
          <div className='menu-item-separator'></div>
          <div className='menu-item-content' name='nav-producers'>
              {
              this.state.producers.map(producer => (
                    <div 
                      key={producer.id} 
                      className={this.state.filters.producers.includes(producer.name) ? 'menu-item-content-item-selected' : 'menu-item-content-item'}
                      onClick={()=>this.onMenuItem('nav-producers', producer)}>
                        {producer.name}
                    </div>
              ))
              }
          </div>
        </div>

        <label className='menu-subtitle'>{window.MENU_CONFIG.section_2.title}</label>

        <div id='menu-price-slider-container'>
          <MultiRangeSlider id='menu-price-slider'
              min={this.state.slider.minValue}
              max={this.state.slider.maxValue}
              step={1}
              minValue={this.state.filters.minPrice}
              maxValue={this.state.filters.maxPrice}
              onChange={(e) => {
                this.handleSlider(e);
              }}
          />
          <div id='menu-price-input-container'>
            <input 
              className='menu-price-input' 
              type='number' 
              min={(this.state.slider.minValue).toString()} 
              max={(this.state.slider.maxValue).toString()} 
              defaultValue={(this.state.filters.minPrice).toString()} 
              onBlur={(event) => this.onInputMinPriceBlur(event.target)}
            />
            <input 
              className='menu-price-input' 
              type='number' 
              min={(this.state.slider.minValue).toString()} 
              max={(this.state.slider.maxValue).toString()} 
              defaultValue={(this.state.filters.maxPrice).toString()} 
              onBlur={(event) => this.onInputMaxPriceBlur(event.target)}
            />
          </div>
        </div>


        <div id='menu-sorting-container'>
            <div id='menu-sorting-input-container'>
              <label id='menu-sorting-label'>{window.MENU_CONFIG.section_3.title}</label>
              <div id='menu-sorting-input' onClick={() => this.openSortingList()}>
                  <label id='menu-sorting-selected'>{window.MENU_CONFIG.section_3.sorting[0].name}</label>
                  <img loading='lazy' id='menu-sorting-icon' src={window.MENU_CONFIG.down_arrow_icon} alt='Apri impostazioni di ordinamento'/>
              </div>
              
            </div>
            <div id='menu-sorting-list' style={{display: 'none'}}>
                {
                  window.MENU_CONFIG.section_3.sorting.map(item => {
                    let sortkey = item.sortkey;
                    let name = item.name;
                    return <label key={sortkey} onClick={() => this.slectSortingValue(sortkey, name)}>{name}</label>
                  })
                }
            </div>
        </div>

        <div id='menu-buttons-container'>
          <Link to='/Enoteca/Catalog' className='menu-button' onClick={() => this.applyFilters()}>
              <label className='menu-button-label'>{window.MENU_CONFIG.button_apply_filters}</label>
          </Link>
          <Link to='/Enoteca/Catalog' className='menu-button' reloadDocument={true}>
              <label className='menu-button-label'>{window.MENU_CONFIG.button_cancel_filters}</label>
          </Link>
        </div>     
      </>
    }
    else {
      title = window.MENU_CONFIG.menu_title;
      menu_pages =
        <div id='menu-special-collection-list'>
          {
            window.MENU_CONFIG.special_collections.map(collection => (
              <Link reloadDocument={true} key={collection.id} to={collection.to} className='menu-special-collection'>
                <label className='menu-special-collection-label'>{collection.menu_name}</label>
              </Link>
            ))
          }
        </div>
    }

    return(
        <div id='menu'>
          <div id='menu-body'>
              <div id='menu-header'>
                  <label className='menu-title'>{title}</label>
                  <img loading='lazy' id='menu-cross' src={window.MENU_CONFIG.close_menu_icon} alt='Chiudi il menù' onClick={() => this.props.closeMenu()}/>
              </div>

              <div id='menu-content'>
                  
                  {menu_pages}
                  {menu_content_filters}  
              </div>
          </div>
        </div>
    );
  }
}

export default Menu;