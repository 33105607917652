import React, {Component, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import '../style/desktop/App.css'
import '../style/mobile/App.css'

import Alert from './Alert'
import Navbar from './Navbar'
import Menu from './Menu';
import Footer from './Footer'
import Loading from './Loading';
import Image  from './Image';

import Storefront from './Storefront';

/* load component with react lazy concept in order to load them only if needed */
const Home = React.lazy(() => import('./Home'));
const ProductPage = React.lazy(() => import('./ProductPage'));
const CartPage = React.lazy(() => import('./CartPage'));
const Catalog = React.lazy(() => import('./Catalog'));
const About = React.lazy(() => import('./About'));
const Enoteca = React.lazy(() => import('./Enoteca'));
const MainProductsPage = React.lazy(() => import('./MainProductsPage'));
const NotFound = React.lazy(() => import('./NotFound'));
const HeaderTenute = React.lazy(() => import('./HeaderTenute'));
const HeaderEnoteca = React.lazy(() => import('./HeaderEnoteca'));
const ServicePage = React.lazy(() => import('./Service'));
const ReviewsPage = React.lazy(() => import('./Reviews'));
const Newsletter = React.lazy(() => import('./Newsletter'));


class App extends Component{

    state = {
        menu_animation_duration: window.MENU_CONFIG.animation_duration,
        alert: {
            state: false,
            title: "example",
            text: "exmaple text",
            type: 'info',
            confirmationDisplay: "none",
            onCancel: null,
            onConfirm: null,
            optionalObject: null
        },
        cartItems: '',
        cart: {lines: {edges: []}},
        order: 'none',
        productTags: [],
        collections: [],
        filters: {
            locations: [],
            typologies: [],
            producers: [],
            minPrice: window.MENU_CONFIG.section_2.min_price,
            maxPrice: window.MENU_CONFIG.section_2.max_price
        },
        searchedTag: '',
        specialCollection: null,
        resize: false,
        filter_enabler: false
    }

    async componentDidMount(){

        let storefront = new Storefront();

        // load tags and collections names
        let productTags = await storefront.getAllTags(250); //250 is the max value available
        let collections = await storefront.getAllCollectionNames(250); //250 is the max value available

        if(productTags == null || collections == null){
            this.openAlert('ERRORE', 'In questo momento il servizio è offline, riprova più tardi', 'warning');
            /* mandare segnalazione */
            return;
        }

        productTags = productTags.edges;
        collections = collections.edges;
        // console.log(productTags); // TEST
        // console.log(collections); // TEST
        this.setState({productTags, collections});

        this.loadCart(storefront);

        // automatic swipe
        let url = window.location.pathname;
        if(url != '/' && url != '/Enoteca' && url != '/Enoteca/')
            this.autoSwipe();
    }

    autoSwipe = () => {
        document.getElementById('go-to-top-button').click();
    }
    // ---------------------- ALERT EVENTS -----------------------
    
    handleCloseAlert = () => {
        let alert = this.state.alert;
        document.getElementById('alert').style.animation = `close-popup ${window.POPUP_animation}ms linear forwards`;
        setTimeout( () => { 
            alert.state = false;
            this.setState(
              {alert},
              function(){
                document.getElementById('blocker').style.display = 'none';
                // document.getElementsByTagName("body")[0].style.overflowY = "auto";
              }
            );    
        }, window.POPUP_animation);
    }
    
    openAlert = (
        title,
        text,
        type, 
        confirmationDisplay = false, 
        optionalObject = null, 
        cancelFunction = null, 
        confirmFunction = null, 
        button_orientation = 'x', 
        cancel_button_text = window.ALERT.cancel_button_text,
        confirm_button_text = window.ALERT.confirm_button_text ) => {

        let alert = this.state.alert;
        alert.state = true;
        alert.title = title;
        alert.text = text;
        alert.type = type;
        alert.confirmationDisplay = confirmationDisplay;
        alert.optionalObject = optionalObject;
        alert.button_orientation = button_orientation;
        alert.cancel_button_text = cancel_button_text;
        alert.confirm_button_text = confirm_button_text;

        if(confirmationDisplay){
            alert.onCancel =  cancelFunction;
            alert.onConfirm = confirmFunction;
        }
        else{
            alert.onCancel = null;
            alert.onConfirm = null;
        }

        this.setState(
          {alert},
          function(){
            // document.getElementsByTagName("body")[0].style.overflowY = "hidden";
            document.getElementById('blocker').style.display = 'block';
            document.getElementById('alert').style.animation = `open-popup ${window.POPUP_animation}ms linear forwards`;
          }
        );
    }
    
    // ------------------- NAVBAR EVENTS ------------------------
    
    loadSearchedProducts = (tag) => {
        
        this.setState({ searchedTag: '', specialCollection: null}, () => {
            this.setState({searchedTag: tag})
        })
        
    }

    openMenu = () => {
        const isMobile = window.matchMedia("(max-width: 1024px)").matches;
        let menuObject = document.getElementById('menu');
        //menuObject.style.display = 'flex';
        menuObject.style.animation = `x-in ${this.state.menu_animation_duration}ms linear forwards`;
        setTimeout(() => {this.setState({resize: true}, () => {this.setState({resize: false})})}, this.state.menu_animation_duration);
        if(isMobile){
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            document.getElementById('blocker-menu').style.display = 'block';
        }
    }

    // ------------------- MENU EVENTS ------------------------

    closeMenu = () => {
        const isMobile = window.matchMedia("(max-width: 1024px)").matches;
        let menuObject = document.getElementById('menu');
        // menuObject.style.display = 'none';
        menuObject.style.animation = `x-out ${this.state.menu_animation_duration}ms linear forwards`;
        setTimeout(() => {this.setState({resize: true, filter_enabler: false}, () => {this.setState({resize: false})})}, this.state.menu_animation_duration);
        if(isMobile){
            document.getElementsByTagName('body')[0].style.overflow = 'auto';
            document.getElementById('blocker-menu').style.display = 'none';
        }
    }

    applyFilters = (filters) => {
        // console.log(filters); // TEST
        this.setState({filters, specialCollection: null});   
    }

    openSpecialCollection = (gid, title) => {
        this.setState({specialCollection: null, searchedTag: ''},
            () => {
                if(gid != null && gid != '')
                    this.setState({specialCollection: {id: gid, title: title}});
            })
    }

    closeFirstCollection = () => {
        this.setState({specialCollection: null, searchedTag: ''});
    }

    openFiltersMenu = () => {
        this.setState({filter_enabler: true}, () => this.openMenu());
    }

    openPagesMenu = () => {
        this.setState({filter_enabler: false}, () => this.openMenu());
    }

    // ------------------ CART EVENT ---------------------

    /* SHOPIFY CART OBJECT
    attributes: [{…}]
    buyerIdentity: {email: null, phone: null, customer: null, countryCode: null}
    checkoutUrl: "https://enoteca-olivieri.myshopify.com/cart/c/c1-a908b4f125508c200b23117ee383782c?key=bbf74f47cfa2fcbbde58b143d08fbbd7"
    cost: {totalAmount: {…}, subtotalAmount: {…}, totalTaxAmount: null, totalDutyAmount: null}
    createdAt: "2023-12-16T11:34:13Z"
    id: "gid://shopify/Cart/c1-a908b4f125508c200b23117ee383782c"
    lines: 
        edges: Array(1)
            0: 
                node: {id: 'gid://shopify/CartLine/9f0a9fba-440d-4124-8e8e-dacdc4959ce5?cart=c1-a908b4f125508c200b23117ee383782c', quantity: 1, merchandise: {…}, attributes: {…}}
                [[Prototype]]: Object
            length: 1
            [[Prototype]]: Array(0)
    totalQuantity: 1
    updatedAt: "2023-12-16T11:34:13Z"
    [[Prototype]]: Object
    */

    loadCart = async (storefront) => {

        // load cart from session storage and storefront
        let cart_shopify = null;
        let cart_backup = null;
        if(sessionStorage.getItem('cart'))
            cart_shopify = await storefront.getCart(sessionStorage.getItem('cart'));
        if(sessionStorage.getItem('cart_backup'))
            cart_backup = JSON.parse(atob(sessionStorage.getItem('cart_backup')));

        // compare cart and cart_backup
        let test = true;
        if(cart_shopify != null && cart_backup != null){
            if(cart_shopify.id == cart_backup.id 
                && cart_shopify.totalQuantity == cart_backup.totalQuantity 
                && cart_shopify.lines.edges.length == cart_backup.lines.edges.length){
                
                for(let i = 0; i < cart_shopify.lines.edges.length; i++){
                    if(cart_shopify.lines.edges[i].node.merchandise.id == cart_backup.lines.edges[i].node.merchandise.id 
                        && cart_shopify.lines.edges[i].node.quantity == cart_backup.lines.edges[i].node.quantity){
                        if(cart_backup.lines.edges[i].node.attributes.length != [] && cart_shopify.lines.edges[i].node.attributes.length == []){
                            cart_shopify = await storefront.updateProductInCart(
                                cart_shopify.id, 
                                cart_shopify.lines.edges[i].node.id, 
                                cart_shopify.lines.edges[i].node.quantity, 
                                cart_backup.lines.edges[i].node.attributes
                            );
                        }
                    }
                    else{
                        test = false;
                        break;
                    }
                }
            }
            else
                test = false;
        }
        else
            test = false;
        
        if(!test){
            this.cleanCart();
        }
        else{
            this.updateCart(cart_shopify);
        }
    }

    addItemToCart = (item, quantity) => {
        
        quantity = parseInt(quantity)
        if (quantity == 0)
            return;

        let storefront = new Storefront();
        return storefront.addProductsToCart(
            this.state.cart.id,
            item.variantId,
            quantity,
            item
        ).then(cart => {

            if(cart == null){
                this.openAlert('ERRORE', 'In questo momento il servizio è offline, riprova più tardi', 'warning');
                /* mandare segnalazione */
                return;
            }
            // console.log(cart);

            this.updateCart(cart);
            // title, text, type, confirmationDisplay = 'none', optionalObject = null, cancelFunction = null, confirmFunction = null 
            this.openAlert(
                '', 
                'Articolo aggiunto con successo al carrello', 
                'info', 
                true, 
                null, 
                this.handleCloseAlert,
                ()=>{
                    let tempLink = document.createElement('a');
                    tempLink.href = '/Enoteca/Cart';
                    tempLink.click();
                },
                'y',
                window.PRODUCT_PAGE_CONFIG.customized_alert.cancel_button_text,
                window.PRODUCT_PAGE_CONFIG.customized_alert.confirm_button_text
            );
        });
        
    }

    updateCart = (cart) => {

        let cartItems = cart.totalQuantity;
        if(cartItems == 0)
            cartItems = '';

        for(let i = 0; i < cart.lines.edges.length; i++){
            if(cart.lines.edges[i].node.quantity == 0){
                cart.lines.edges.splice(i, 1);
                i--;
                continue;
            }
            if(cart.lines.edges[i].node.attributes.length == 0){
                console.error('cart not valid'); // TEST
                this.cleanCart();
                this.openAlert('ERROR', 'Carrello non valido', 'warning');
                return false;
            }
            cart.lines.edges[i].node.attributes = JSON.parse(cart.lines.edges[i].node.attributes[0].value)
        }

        // console.log(cart); // TEST
        
        this.setState(
            {cartItems, cart}
        );
        sessionStorage.setItem('cart_backup', btoa(JSON.stringify(cart)));
        return true;
    }

    cleanCart = async () => {

        // console.log('new cart'); // TEST
        let storefront = new Storefront();
        const cart = await storefront.crateCart();

        if(cart == null){
            this.openAlert('ERRORE', 'In questo momento il servizio è offline, riprova più tardi', 'warning');
            /* mandare segnalazione */
            return;
        }
        
        sessionStorage.setItem('cart', cart.id);
        sessionStorage.setItem('cart_backup', btoa(JSON.stringify(cart)));
        this.setState(
            { cartItems: '', cart }
        );

    }

    // ------------------------------------------------
    render(){

        let alert;

        if(this.state.alert.state){
            alert = <Alert
                      alert = {this.state.alert}
                      closeAlert = {this.handleCloseAlert}
                    />
          }
          else
            alert = <></>   

        let promo = <></>
        let promo_style = {
            borderTop: window.PROMO.border_color,
            borderBottom: window.PROMO.border_color,
        }
        if (window.PROMO.enable)
          promo = <>
            <Link reloadDocument={true} to={window.PROMO.link} id="promo-container-desktop" style={promo_style}>
                <Image className="promo-image" src={window.PROMO.image_desktop} alt='Promozione'/>
            </Link>
            <Link reloadDocument={true} to={window.PROMO.link} id="promo-container-mobile" style={promo_style}>
                <Image className="promo-image" src={window.PROMO.image_mobile} alt='Promozione'/>
            </Link>
            </>
        return (
            <Router>
                    <div id="main-page">
                        <div id="blocker" />
                        <div id="blocker-menu" onClick={() => this.closeMenu()}/>
                        <ScrollLink id="go-to-top-button" smooth={true} to={'nav'} duration={window.SCROLL_LINK_animation_duration}>
                            <Image src={window.TOTOPBUTTON_IMAGE} alt="Bottone per tornare all'inizio della pagina" />
                        </ScrollLink>
                        {promo}
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route
                                    exact path='/Enoteca/*'
                                    element={
                                        <>
                                        <HeaderEnoteca />
                                        <Navbar 
                                            color = '#000000'
                                            cartItems = {this.state.cartItems}
                                            openMenu = {this.openPagesMenu}
                                            productTags = {this.state.productTags}
                                            autoSwipe = {this.autoSwipe}
                                
                                        />
                                        </>
                                    }
                                />
                                <Route
                                    exact path='*'
                                    element={
                                        <>
                                        <HeaderTenute />
                                        <Navbar 
                                            color = '#455727'
                                            cartItems = {this.state.cartItems}
                                            openMenu = {this.openPagesMenu}
                                            productTags = {this.state.productTags}
                                            autoSwipe = {this.autoSwipe}
                                        />
                                        </>
                                    }
                                />
                            </Routes>
                        </Suspense>
                        <div id='main-body'>
                            <Menu 
                                collections = {this.state.collections}
                                applyFilters = {this.applyFilters}
                                filters = {this.state.filters}
                                closeMenu = {this.closeMenu}
                                autoSwipe = {this.autoSwipe}
                                filter_enabler = {this.state.filter_enabler}
                            />
                            <Suspense fallback={<Loading />}>
                                <Routes>
                                    <Route
                                        exact path='/'
                                        element={
                                            <Home
                                                openAlert = {this.openAlert}
                                                resize = {this.state.resize}
                                            />
                                        }  
                                    />
                                    <Route
                                        exact path='/Home'
                                        element={
                                            <Home
                                                openAlert = {this.openAlert}
                                                resize = {this.state.resize}
                                            />
                                        }  
                                    />
                                    <Route 
                                        exact path='/Enoteca/Product/:productId'
                                        element={
                                            <ProductPage
                                                item = {this.state.productCard}
                                                addToCart = {this.addItemToCart}
                                                openAlert = {this.openAlert}
                                                resize = {this.state.resize}
                                            />
                                        }
                                    />
                                    <Route 
                                        exact path='/Enoteca/Catalog/*' 
                                        element={
                                            <Catalog
                                                openSpecialCollection = {this.openSpecialCollection}
                                                searchedTag = {this.state.searchedTag}
                                                filters = {this.state.filters}
                                                specialCollection = {this.state.specialCollection}
                                                closeFirstCollection = {this.closeFirstCollection}
                                                openAlert = {this.openAlert}
                                                applyFilters = {this.applyFilters}
                                                resize = {this.state.resize}
                                                loadSearchedProducts = {this.loadSearchedProducts}
                                                openFilters = {this.openFiltersMenu}
                                            />
                                        } 
                                    />
                                    <Route 
                                        exact path='/Own-products' 
                                        element={
                                            <MainProductsPage
                                                openAlert = {this.openAlert}
                                                resize = {this.state.resize}
                                            />
                                        } 
                                    />
                                    <Route 
                                        path='/Enoteca/Cart'
                                        element={
                                            <CartPage
                                                closeCartPage = {this.closeCartPage}
                                                updateCart = {this.updateCart}
                                                openAlert = {this.openAlert}
                                                closeAlert = {this.handleCloseAlert}
                                                cart = {this.state.cart}
                                                cleanCart = {this.cleanCart}
                                            />
                                        }
                                    />
                                    <Route
                                        path='/About'
                                        element={
                                            <About />
                                        }
                                    />
                                    <Route 
                                        path='/Enoteca'
                                        element={
                                            <Enoteca 
                                                openAlert = {this.openAlert}
                                                resize = {this.state.resize}
                                            />
                                        }
                                    />
                                    <Route
                                        path='/Service'
                                        element={
                                            <ServicePage />
                                        }
                                    />
                                    <Route 
                                        path='/Enoteca/Reviews'
                                        element={
                                            <ReviewsPage
                                                openAlert = {this.openAlert}
                                            />
                                        }
                                    />
                                    <Route 
                                        path='/Enoteca/Newsletter'
                                        element={
                                            <Newsletter
                                                openAlert = {this.openAlert}
                                            />
                                        }
                                    />
                                    <Route path='*' element={<NotFound />} />
                                </Routes>
                            </Suspense>
                        </div>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route
                                    exact path='/Enoteca/*'
                                    element={
                                        <Footer   
                                            color = '#000000' 
                                            openAlert = {this.openAlert}
                                        />
                                    }
                                />
                                <Route
                                    exact path='*'
                                    element={
                                        <Footer  
                                            color = '#455727'  
                                            openAlert = {this.openAlert}
                                        />
                                    }
                                />
                            </Routes>
                        </Suspense>
                        {alert}
                    </div>
                </Router>
        );
    }
}

export default App;