import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import '../style/desktop/Navbar.css'
import '../style/mobile/Navbar.css'
import Storefront from './Storefront';


class Navbar extends Component{

  state = {
    search_animation_duration: window.NAVBAR_CONFIG.search_animation_duration,
    cartItems: '',
    searchProductResults: [],
    searchTagResults: [],
    config: {
      search_icon: window.NAVBAR_CONFIG.search_icon,
      close_search_icon: window.NAVBAR_CONFIG.close_search_icon,
      cart_icon: window.NAVBAR_CONFIG.cart_icon,
      menu_icon: window.NAVBAR_CONFIG.menu_icon,
      title: window.NAVBAR_CONFIG.title,
      subtitle: window.NAVBAR_CONFIG.subtitle,
    }
  }

  onSearch(){
    document.getElementById('nav-search-container').style.animation = `search-open ${this.state.search_animation_duration}ms linear forwards`;
  }

  onSearchCross(){
    document.getElementById('nav-search-input').value = '';
    this.setState({searchProductResults: [], searchTagResults: []});
    document.getElementById('nav-search-container').style.animation = `search-close ${this.state.search_animation_duration}ms linear forwards`;
  }

  onSearchResult = () => {
    document.getElementById('nav-search-input').value = '';
    this.setState({searchProductResults: [], searchTagResults: []});
    window.scrollTo(0,0);
  }

  async searchTyping(key){

    document.getElementById('nav-search-container').style.overflow = 'visible';
    let value = document.getElementById('nav-search-input').value;
    // console.log('LOG: search ' + value);
    if (value == ""){
      this.setState({searchProductResults: [], searchTagResults: []});
      return;
    }

    if(key == 'Enter'){
      if(document.getElementsByClassName('nav-search-tag-link').length > 0)
        document.getElementsByClassName('nav-search-tag-link')[0].click();
      else if(document.getElementsByClassName('nav-search-product-link').length > 0)
        document.getElementsByClassName('nav-search-product-link')[0].click();
      return;
    }

    // tag searching
    let searchingText = value;
        
    let items = this.props.productTags;
    let searchTagResults = [];
    for(let i = 0; i < items.length; i++){
      if((items[i].node.toLowerCase()).search(searchingText.toLowerCase()) == -1)
        continue;
      else
        searchTagResults.push(items[i].node);
    }

    this.setState({searchTagResults});

    if(value.length <= 0){
      this.setState({searchProductResults: []})
      return;
    }

    // product seraching

    let storefront = new Storefront();
    let results = await storefront.searchProducts(value);
    if(results == null)
      return;
    let searchProductResults = [];
    for(let i = 0; i < results.products.length; i++){

      let product = results.products[i];
      let result = {};
      result.id = product.id.replace('gid://shopify/Product/', '');
      result.name = product.title;
      result.producer = product.vendor;
      result.type = product.productType;
      result.image = product.featuredImage.url;
      searchProductResults.push(result);
    }

    // console.log(searchResults);
    this.setState({searchProductResults})
  }

  render(){

    let search_container2 = <></>;
    search_container2 = 
    <div id='nav-search-container1'>
      <input type='text' id='nav-search-input' placeholder='Cosa vuoi cercare?' onKeyUp={(event) => this.searchTyping(event.key)}/>
      <img loading='lazy' id='nav-search-cross' src={this.state.config.close_search_icon} onClick={() => this.onSearchCross()} alt='Chiudi la barra di ricerca'/>
    </div>

    return(
      <nav id="nav" style={{backgroundColor: this.props.color}}>
        <Waypoint 
              onEnter={() => {document.getElementById('go-to-top-button').style.display = 'none';}}
              onLeave={() => {document.getElementById('go-to-top-button').style.display = 'flex';}}
        />
        <div id='nav-container0'>

          <div id='nav-container-1'>
            <div className='nav-button-container-sx'>
                <img loading='lazy' id="nav-menu-img" src={this.state.config.menu_icon} onClick={() => {this.props.autoSwipe(); this.props.openMenu()}} alt='Apri il menù'/>
            </div>
            <div id='nav-pages'>
              {
                window.NAVBAR_CONFIG.links.map(link => {
                  if(!link.mobile)
                    return <Link reloadDocument={true} to={link.to} key={link.name} id='nav-page-link-rem' className='nav-page-link'>
                      <label className='nav-page-label'>{link.name}</label>
                    </Link>
                  else
                    return <Link reloadDocument={true} to={link.to} key={link.name} className='nav-page-link'><label className='nav-page-label'>{link.name}</label></Link>
                })
              }
            </div>
            <div className='nav-button-container-dx'>
              <img loading='lazy' id="nav-search-img" src={this.state.config.search_icon} onClick={() => {this.props.autoSwipe(); this.onSearch()}} alt='Mostra la barra di ricerca'/>
              <Link reloadDocument={true} to='/Enoteca/Cart'>
                <div id='nav-cart-container'>
                  <img loading='lazy' id='nav-cart-img' src={this.state.config.cart_icon} alt='Vai al carrello'/>
                  <label id='nav-cart-counter'>{this.props.cartItems}</label>
                </div>
              </Link>
            </div>
          </div>

          <div id='nav-search-container'>
            {search_container2}
            <div id='nav-search-results-container'>
                {
                  this.state.searchTagResults.map(result => (
                    <Link reloadDocument={true} className='nav-search-results-link nav-search-tag-link' to={'/Enoteca/Catalog/search/' + result} key={result}>
                      <label className='nav-search-results-label'>{result}</label>
                    </Link>
                  ))
                }
                {
                  this.state.searchProductResults.map(result => (
                    <Link reloadDocument={true} className='nav-search-results-link nav-search-product-link' key={result.id} to={'/Enoteca/Product/'+result.id}>
                      <label className='nav-search-results-label' >{`${result.name} | ${result.producer} | ${result.type}`}</label>
                      <img loading='lazy' className='nav-search-results-image' src={result.image} alt='...'/>
                    </Link>
                  ))
                }
              </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;