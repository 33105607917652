import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Iframe from 'react-iframe';
import Image from './Image';

import '../style/desktop/Footer.css'
import '../style/mobile/Footer.css'

class Footer extends Component{

    render(){
        return(
        <div id='footer' style={{backgroundColor: this.props.color}}>
            <div id='footer-container'>
                <div id='footer-body'>
                    <div id='footer-contacts'>
                        <div className='footer-section-container'>
                            <div className='footer-section-header'>
                                <div className='footer-section-logo-container'>
                                    <img id='footer-section-logo1' src={window.FOOTER.section1.logo} alt='...'/>
                                </div>
                                <label className='footer-section-title'>{window.FOOTER.section1.title}</label>
                            </div>
                            <div className='footer-social-container'>
                                {
                                    window.FOOTER.section1.social.map( social => (
                                        <a key={social.id} className='footer-social' href={social.link} target='_blank' rel='noopener noreferrer'>
                                            <img loading='lazy' className='footer-social-image' src={social.img} alt='...'/>
                                        </a>
                                    ))
                                }
                            </div>
                            <div className='footer-contacts-container'>
                                {
                                    window.FOOTER.section1.contacts.map( contact => (
                                        <a key={contact.id} className='footer-contact' href={contact.link} target='_blank' rel='noopener noreferrer'>
                                            <label className='footer-contact-label'>{contact.text}</label>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='footer-section-container'>
                            <div className='footer-section-header'>
                                <div className='footer-section-logo-container'>
                                    <img id='footer-section-logo2' src={window.FOOTER.section2.logo} alt='...'/>
                                </div>
                                <label className='footer-section-title'>{window.FOOTER.section2.title}</label>
                            </div>
                            <div className='footer-social-container'>
                                {
                                    window.FOOTER.section2.social.map( social => (
                                        <a key={social.id} className='footer-social' href={social.link} target='_blank' rel='noopener noreferrer'>
                                            <img loading='lazy' className='footer-social-image' src={social.img} alt='...'/>
                                        </a>
                                    ))
                                }
                            </div>
                            <div className='footer-contacts-container'>
                                {
                                    window.FOOTER.section2.contacts.map( contact => (
                                        <a key={contact.id} className='footer-contact' href={contact.link} target='_blank' rel='noopener noreferrer'>
                                            <label className='footer-contact-label'>{contact.text}</label>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div id='footer-legal-area-container'>
                        <label id='footer-legal-area-title'>{window.FOOTER.legal_area.title}</label>
                        <div className='footer-contacts-container'>
                            {
                                window.FOOTER.legal_area.list.map( contact => (
                                    <a key={contact.id} className='footer-contact' href={contact.link} target='_blank' rel='noopener noreferrer'>
                                        <label className='footer-contact-label'>{contact.text}</label>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div id='footer-end-container'>
                    <div id='footer-end-payments-list'>
                        {
                            window.FOOTER.end.payments.map( payment => (
                                <div key={payment.id} className='footer-end-payment-container'> 
                                    <img className='footer-end-payment' src={payment.img} alt='...'/>
                                </div>
                            ))
                        }
                    </div>
                    <div id='footer-end'>
                        <label id='footer-end-label'>{window.FOOTER.end.piva}</label>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;