import React, {Component} from 'react';
import '../style/desktop/Loading.css'
import '../style/mobile/Loading.css'

class Loading extends Component{

    state = {
        
    }

    componentDidMount(){
       
    }
    render(){
        return(
            <div id="loading">
                <img loading='lazy' id='loading-img' src={window.LOADING_PAGE} alt='...' />
            </div>
        );
    }
}


export default Loading;