import React, {Component} from 'react';
import Image from './Image';

import '../style/desktop/Alert.css'
import '../style/mobile/Alert.css'

class Alert extends Component{

    state = {
        title: this.props.alert.title,
        text: this.props.alert.text,
        type: this.props.alert.type,
        confirmationDisplay: this.props.alert.confirmationDisplay,
        image: window.ALERT.info_img,
        button_orientation: this.props.alert.button_orientation,
        confirm_button_text: this.props.alert.confirm_button_text,
        cancel_button_text: this.props.alert.cancel_button_text
    }

    componentDidMount(){
        let type = this.state.type;
        if (type == 'info'){
            this.setState({image: window.ALERT.info_img});
        }
        else if (type == 'warning'){
            this.setState({image: window.ALERT.warning_img})
        }
    }
    render(){

        let confirmation_area = <></>
        if(this.state.confirmationDisplay && this.state.button_orientation == 'x')
            confirmation_area = <div id="alert-container3-1">
                <button className="alert-button" onClick={()=>this.props.alert.onCancel()}>{this.state.cancel_button_text}</button>
                <button className="alert-button" onClick={()=>this.props.alert.onConfirm()}>{this.state.confirm_button_text}</button>
            </div>
        else if(this.state.confirmationDisplay && this.state.button_orientation == 'y')
            confirmation_area = <div id="alert-container3-2">
                <button className="alert-button" onClick={()=>this.props.alert.onCancel()}>{this.state.cancel_button_text}</button>
                <button className="alert-button" onClick={()=>this.props.alert.onConfirm()}>{this.state.confirm_button_text}</button>
            </div>
        return(
            <div id="alert">
                <div id='alert-container'>
                    <div id="alert-container1">
                        <label id="alert-title">{this.state.title}</label>
                        <img loading='lazy' id="alert-cross" onClick={() => this.props.closeAlert()} src={window.ALERT.close_img}/>
                    </div>
                    <div id="alert-container2">
                        <img loading='lazy' id="alert-image" src={this.state.image}/>
                        <p id="alert-text">{this.state.text}</p>
                    </div>
                    {confirmation_area}
                </div>
            </div>
        );
    }
}

export default Alert;